import { without } from 'lodash'
import {
  AUTHORITIES_KEY,
  EXPIRATION_TIME_KEY,
  INVITE_TOKENS_KEY,
  ORIGIN_CASES_DATA_KEY,
  TASKS_SLIDE_DATA,
  TOKEN_KEY,
  USER_KEY,
  WORKSPACE_ID_KEY,
} from 'shared/lib/common/storageKeys'
import { ILocalStorageTasksSlide } from 'types/IMarkupSlide'
import { IGroupType } from 'types/ISlide'
import IUser from 'types/IUser'
import IUserData from 'types/IUserData'
import IUserRole from 'types/IUserRole'
import { OriginCaseData } from 'viewer/tools/ui/all-cases/AllCasesContainer'

export const getUserDataFromStorage = () => {
  const token = localStorage.getItem(TOKEN_KEY)
  const expirationTime = localStorage.getItem(EXPIRATION_TIME_KEY)
  const user = localStorage.getItem(USER_KEY)
  const authorities = localStorage.getItem(AUTHORITIES_KEY)
  const authoritiesArray = authorities ? JSON.parse(authorities) : []
  const userObject = user ? JSON.parse(user) : {}

  return {
    authorities: authoritiesArray || undefined,
    expirationTime: Number(expirationTime) || undefined,
    token: token || undefined,
    user: {
      ...userObject,
      userId: userObject.userId || userObject.id,
    } as IUser,
  }
}

export const setUserDataToStorage = (userData: IUserData) => {
  localStorage.setItem(TOKEN_KEY, userData.token || '')
  localStorage.setItem(EXPIRATION_TIME_KEY, userData?.expirationTime?.toString() || '')
  localStorage.setItem(USER_KEY, JSON.stringify(userData.user) || '')
  localStorage.setItem(AUTHORITIES_KEY, JSON.stringify(userData.authorities) || '')
}

export const removeUserDataFromStorage = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(EXPIRATION_TIME_KEY)
  localStorage.removeItem(USER_KEY)
  localStorage.removeItem(AUTHORITIES_KEY)
  localStorage.removeItem(WORKSPACE_ID_KEY)
}

export const getTokenFromStorage = () => localStorage.getItem(TOKEN_KEY)

export const setInviteTokenToStorage = (inviteToken: string) => {
  const tokensArray = getInviteTokensFromStorage()
  tokensArray.push(inviteToken)
  localStorage.setItem(INVITE_TOKENS_KEY, JSON.stringify(tokensArray))
}
export const getInviteTokensFromStorage = (): string[] => {
  const inviteTokens = localStorage.getItem(INVITE_TOKENS_KEY)
  return inviteTokens && Array.isArray(JSON.parse(inviteTokens)) ? JSON.parse(inviteTokens) : []
}

export const removeInviteTokenFromStorage = (inviteToken: string) => {
  const tokensArray = getInviteTokensFromStorage()
  localStorage.setItem(INVITE_TOKENS_KEY, JSON.stringify(without(tokensArray, inviteToken)))
}

export const removeAllInviteTokensFromStorage = () => {
  localStorage.removeItem(INVITE_TOKENS_KEY)
}

export const getTasksSlideData = () => localStorage.getItem(TASKS_SLIDE_DATA)

export const setTasksSlideData = (data?: ILocalStorageTasksSlide[]) => {
  localStorage.setItem(TASKS_SLIDE_DATA, JSON.stringify(data))
}
export const isGuestHandler = (authorities?: IUserRole[]) =>
  authorities?.includes(IUserRole.ROLE_USER) ? false : authorities?.includes(IUserRole.ROLE_GUEST)

export const saveCasesData = (
  urlCaseId: number,
  groupType: IGroupType = 'MICRO',
  slideId: number,
  path: string,
): void => {
  const caseData: OriginCaseData = {
    caseId: urlCaseId,
    groupType,
    path,
    slideId,
  }

  // Получаем данные из localStorage
  const storedData: OriginCaseData[] = JSON.parse(localStorage.getItem(ORIGIN_CASES_DATA_KEY) || '[]')

  // Проверяем, существует ли объект с таким caseId
  const existingCaseIndex = storedData.findIndex((data) => data.caseId === urlCaseId)

  if (existingCaseIndex !== -1) {
    // Если объект найден, перезаписываем его
    storedData[existingCaseIndex] = caseData
  } else {
    // Если объекта нет, добавляем новый
    storedData.push(caseData)
  }

  // Сохраняем обновленный массив в localStorage
  localStorage.setItem(ORIGIN_CASES_DATA_KEY, JSON.stringify(storedData))
}

// Функция для поиска объекта по slideId
export const findCaseBySlideId = (caseId: number): OriginCaseData | undefined => {
  const storedData: OriginCaseData[] = JSON.parse(localStorage.getItem(ORIGIN_CASES_DATA_KEY) || '[]')

  return storedData.find((caseData) => caseData.caseId === caseId)
}

export const addToLocalStorage = <T>(key: string, value: T): void => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getFromLocalStorage = <T>(key: string): T | null => {
  const dataString = localStorage.getItem(key)
  return dataString ? (JSON.parse(dataString) as T) : null
}
